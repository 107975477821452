




















































import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
import Dialog from '@/calendesk/models/Dialog'
import DialogSize from '@/calendesk/models/DialogSize'
import { DialogTypes } from '../../DialogTypes'
import mixins from 'vue-typed-mixins'
export default mixins(DialogMixins).extend({
  name: 'LoginDialog',
  components: {
    LoginDialogForm: () => import('./LoginDialogForm.vue')
  },
  computed: {
    loginExternalConfiguration (): Record<string, any> {
      return this.draftConfiguration.login_modal_configuration
    }
  },
  methods: {
    signUpHandler () {
      this.openDialog2(new Dialog(true, DialogTypes.SIGN_UP, this.$trans('sign_up'), DialogSize.MIDDLE))
    },
    forgottenPasswordHandler () {
      this.openDialog2(new Dialog(true, DialogTypes.FORGOTTEN_PASSWORD, this.draftConfiguration.forgotten_password_modal_configuration.wb_forgotten_password_title__text__, DialogSize.SMALL))
    }
  }
})
